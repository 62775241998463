import { Typography } from "@mui/material"
import formatDuration from "format-duration"
import { useEffect, useState } from "react"
import makeLightDark from "../material-ui/makeLightDark"

export const RecordingDuration: React.FC<{ start: number }> = ({ start }) => {
  const [duration, setDuration] = useState(0)
  useEffect(() => {
    const timer = setInterval(() => {
      setDuration(new Date().valueOf() - start)
    }, 100)
    return () => {
      clearInterval(timer)
    }
  }, [start])

  const color = makeLightDark({ color: "dimgrey" }, { color: "silver" })

  return (
    <Typography variant="caption" sx={{ color, opacity: 0.8, marginRight: 1, userSelect: "none" }}>
      {formatDuration(duration)}
    </Typography>
  )
}
