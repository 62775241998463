import { usePersonable } from "@cs124/personable"
import { Alert, capitalize } from "@mui/material"
import { useSession } from "next-auth/react"
import React, { useEffect, useState } from "react"
import { DEVELOPMENT } from "../../constants"
import { LoginButton } from "../login"
import { P } from "../material-ui"
import { useID } from "./IdableProvider"

export const ShowID: React.FC<{ noTeam: boolean }> = props => {
  const { status } = useSession()
  const { ID, team } = useID()
  const { course } = usePersonable()
  const isStaff = course?.isStaff === true
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted || !ID) {
    return null
  }
  if (status !== "authenticated") {
    return (
      <Alert severity="error">
        <P>Please log in to see your MP ID.</P>
        <LoginButton sx={{ marginTop: 1 }} />
      </Alert>
    )
  }
  return (
    <Alert severity="info">
      <P>
        Your MP ID is <kbd>{DEVELOPMENT && isStaff ? "0".repeat(27) : ID}</kbd>
      </P>
      <P>
        Copy this value into <kbd>ID.txt</kbd> in the root of your project directory.
      </P>
      {!props.noTeam && <P>You&apos;re on the {capitalize(team)} Team.</P>}
    </Alert>
  )
}
