import { Alert, AlertTitle, Button, Snackbar, Typography } from "@mui/material"
import { detect } from "detect-browser"
import moment from "moment-timezone"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { Number, Record } from "runtypes"
import { A } from "~/components/mdx"
import { DISABLE_COOKIE_WARNING, MAX_TIME_DIFF_SECONDS, SHOW_VERSION_WARNINGS } from "~/constants"
import { useBuildId } from "./BuildIdProvider"

const browser = detect()

const TimestampResponse = Record({
  timestamp: Number,
})

export const Warnings: React.FC = () => {
  const [shouldShowCookies, setShowCookies] = useState(false)
  const [shouldShowBrowser, setShowBrowser] = useState(false)
  const [timeWarning, setTimeWarning] = useState<
    { serverTime: number; clientTime: number; timeDiffSeconds: number } | undefined
  >()

  const { buildIdMismatch } = useBuildId()
  const router = useRouter()

  const closeCookies = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    localStorage.setItem("warnings:cookies", new Date().toString())
    setShowCookies(false)
  }

  const closeBrowser = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    localStorage.setItem("warnings:browser", new Date().toString())
    setShowBrowser(false)
  }

  const closeTime = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setTimeWarning(undefined)
  }

  useEffect(() => {
    fetch(`/api/timecheck`)
      .then(r => r.json())
      .then(response => {
        const clientTime = new Date().valueOf()
        const timestampResponse = TimestampResponse.check(response)
        const serverTime = timestampResponse.timestamp

        const timeDiffSeconds = Math.abs((clientTime - serverTime) / 1000)
        timeDiffSeconds > MAX_TIME_DIFF_SECONDS
          ? setTimeWarning({ serverTime, clientTime, timeDiffSeconds })
          : setTimeWarning(undefined)
      })
      .catch(err => {
        console.warn(err)
      })
  }, [])

  useEffect(() => {
    if (!localStorage.getItem("warnings:cookies")) {
      setShowCookies(true)
    }
    if (!localStorage.getItem("warnings:browser") && browser.name !== "chrome" && browser.name !== "firefox") {
      setShowBrowser(true)
    }
  }, [])

  const shouldShowVersion = SHOW_VERSION_WARNINGS && buildIdMismatch

  const showVersion = shouldShowVersion
  const showCookies = !showVersion && shouldShowCookies && !DISABLE_COOKIE_WARNING
  const showBrowser = !showCookies && shouldShowBrowser
  const showTime = !showBrowser && !!timeWarning

  return (
    <>
      <Snackbar sx={{ maxWidth: 360 }} open={showVersion} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert severity="warning">
          <AlertTitle>Outdated Version</AlertTitle>
          <Typography paragraph variant="caption">
            Your version of <Typography variant="kbd">cs124.org</Typography> is out of date. Save your work and click
            below to reload.
          </Typography>
          <Button variant="outlined" color="primary" onClick={router.reload}>
            Reload
          </Button>
        </Alert>
      </Snackbar>

      <Snackbar sx={{ maxWidth: 360 }} open={showCookies} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert severity="info">
          <AlertTitle>This Site Uses Cookies</AlertTitle>
          <Typography paragraph variant="caption">
            We use cookies to help you learn as much as you can this semester.
          </Typography>
          <Button variant="outlined" color="primary" onClick={closeCookies}>
            Agree
          </Button>
        </Alert>
      </Snackbar>

      <Snackbar sx={{ maxWidth: 360 }} open={showBrowser} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert severity="warning" onClose={closeBrowser}>
          <AlertTitle>Browser Warning</AlertTitle>
          <Typography paragraph variant="caption">
            This site works best on <A href="https://www.google.com/chrome/">Chrome</A> or{" "}
            <A href="https://www.mozilla.org/en-US/firefox/new/">Firefox</A>.
          </Typography>
        </Alert>
      </Snackbar>

      {timeWarning && (
        <>
          <Snackbar sx={{ maxWidth: 360 }} open={showTime} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
            <Alert severity="warning" onClose={closeTime}>
              <AlertTitle>Your Clock Is Wrong</AlertTitle>
              <Typography paragraph variant="caption">
                Your computer&apos;s clock is more than {MAX_TIME_DIFF_SECONDS} seconds off! The time at the University
                of Illinois is {moment(timeWarning.serverTime).tz("America/Chicago").format("YYYY-MM-DD HH:mm:ss")}, but
                your computer reports{" "}
                {moment(timeWarning.clientTime).tz("America/Chicago").format("YYYY-MM-DD HH:mm:ss")}, a{" "}
                {Math.round(timeWarning.timeDiffSeconds)} second difference.
              </Typography>
              <Typography paragraph variant="caption">
                Please correct your machine&apos;s time or parts of the website interface will malfunction.
              </Typography>
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  )
}
