import { ClientIDProvider } from "@cs124/client-id"
import { ElementTracker, ElementTrackerServer } from "@cs124/element-tracker"
import { HelpableProvider } from "@cs124/helpable"
import { JeedProvider } from "@cs124/jeed-react"
import { Lesson } from "@cs124/lesson"
import { MaceProvider } from "@cs124/mace"
import { currentSemester, FullResponse, hasCurrentActiveRole, SEMESTER } from "@cs124/person"
import { PersonableProvider, usePersonable } from "@cs124/personable"
import { JitsiWrapper } from "@cs124/react-jitsi"
import { AppCacheProvider } from "@mui/material-nextjs/v14-pagesRouter"
import CssBaseline from "@mui/material/CssBaseline"
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles"
import { SessionProvider, useSession } from "next-auth/react"
import { AppProps } from "next/app"
import Head from "next/head"
import Script from "next/script"
import { PropsWithChildren, useEffect, useRef } from "react"
import "react-popper-tooltip/dist/styles.css"
import { Array } from "runtypes"
import { HashProvider } from "~/components/element-tracker/HashProvider"
import ErrorBoundary from "~/components/errors/ErrorBoundary"
import { HelpableChecker } from "~/components/helpable/HelpableChecker"
import { RequestRefresher } from "~/components/helpable/RequestRefresher"
import { BuildIdProvider } from "~/components/helper/BuildIdProvider"
import { Warnings } from "~/components/helper/Warnings"
import { IdableProvider } from "~/components/idable"
import { TopBarProvider } from "~/components/layout"
import { LessonsProvider } from "~/components/lessons"
import lessons from "~/components/lessons/lessons.json"
import { ThemeProvider } from "~/components/material-ui"
import { theme } from "~/components/material-ui/theme"
import { ScheduleProvider } from "~/components/schedulable"
import { ShareableProvider, SharingProvider } from "~/components/shareable"
import {
  API_SERVER,
  COOKIE_DOMAIN,
  CURRENT_NUMBER,
  HELPABLE_SERVER,
  JEED_SERVER,
  JITSI_SERVER,
  MACE_SERVER,
  PERSONABLE_SERVER,
} from "~/constants"
import "~/styles/Javadoc.scss"
import "~/styles/Kotlindoc.scss"
import "~/styles/ace.scss"
import "~/styles/global.scss"

const MiddlewareChecker: React.FC<PropsWithChildren> = ({ children }) => {
  const { status, data, update } = useSession()
  const { course, loaded } = usePersonable()
  const roleUpdated = useRef(false)

  useEffect(() => {
    if (!loaded || roleUpdated.current) {
      return
    }
    const correctRole = course?.category === "staff" ? "staff" : course?.category === "student" ? "student" : "none"
    const actualRole = status === "authenticated" ? data.user.role : "none"
    if (correctRole !== actualRole) {
      update()
      roleUpdated.current = true
    }
  }, [loaded, course?.isStaff, course?.category, status, data, update])

  return children
}

const InsideLogin: React.FC<PropsWithChildren<{ course?: FullResponse }>> = ({ course, children }) => {
  const { status } = useSession()

  return (
    <PersonableProvider
      course={course}
      server={PERSONABLE_SERVER}
      semester={SEMESTER}
      loggedIn={status === "authenticated"}
      shouldConnect={status !== "loading"}
    >
      <MiddlewareChecker>
        <ElementTrackerServer>
          <MaceProvider server={MACE_SERVER} loggedIn={status === "authenticated"} shouldConnect={status !== "loading"}>
            <JeedProvider server={JEED_SERVER}>
              <IdableProvider server={API_SERVER}>
                <ShareableProvider>
                  <ElementTracker>
                    <ScheduleProvider>
                      <WrapWithHelpable>
                        <WrapWithJitsi>
                          <SharingProvider>
                            <HashProvider delay={HASH_DELAY}>
                              <TopBarProvider>{children}</TopBarProvider>
                            </HashProvider>
                          </SharingProvider>
                        </WrapWithJitsi>
                      </WrapWithHelpable>
                    </ScheduleProvider>
                  </ElementTracker>
                </ShareableProvider>
              </IdableProvider>
            </JeedProvider>
          </MaceProvider>
        </ElementTrackerServer>
      </MiddlewareChecker>
    </PersonableProvider>
  )
}

const WrapWithJitsi: React.FC<PropsWithChildren<unknown>> = ({ children }) =>
  JITSI_SERVER ? <JitsiWrapper url={JITSI_SERVER}>{children}</JitsiWrapper> : <>{children}</>

const WrapWithHelpable: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { impersonating, course } = usePersonable()
  const { status } = useSession()

  if (!HELPABLE_SERVER) {
    return <>{children}</>
  }
  const loggedIn = status === "authenticated" && hasCurrentActiveRole(course?.roles || [], currentSemester)
  return (
    <HelpableProvider
      semester={SEMESTER}
      number={CURRENT_NUMBER}
      server={HELPABLE_SERVER}
      loggedIn={loggedIn}
      staff={!!course?.isStaff}
      email={course?.you?.email}
      impersonating={impersonating}
    >
      <RequestRefresher>
        <HelpableChecker>{children}</HelpableChecker>
      </RequestRefresher>
    </HelpableProvider>
  )
}

function waitForElement(selector: string, timeout: number) {
  return new Promise<Element>((resolve, reject) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect()
        resolve(document.querySelector(selector))
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    setTimeout(() => {
      observer.disconnect()
      reject()
    }, timeout)
  })
}

const HASH_DELAY = 512

export default function MyApp(props: AppProps) {
  const { Component, pageProps } = props
  const { session, course } = pageProps

  const hash = (typeof window !== "undefined" && window.location.hash.slice(1)) || ""
  useEffect(() => {
    if (!hash) {
      return
    }
    waitForElement(`#${hash}`, HASH_DELAY - 32)
      .then(element => {
        window.history.replaceState(window.history.state, "", `#${hash}`)
        element.scrollIntoView()
      })
      .catch(() => {
        console.warn(`Couldn't find hash ${hash}`)
        window.location.hash = ""
      })
  }, [hash])

  return (
    <AppCacheProvider {...props}>
      <Script
        strategy="afterInteractive"
        src="https://unpkg.com/source-map@0.7.3/dist/source-map.js"
        onLoad={() => {
          window.sourceMap.SourceMapConsumer.initialize({
            "lib/mappings.wasm": "https://unpkg.com/source-map@0.7.3/lib/mappings.wasm",
          })
        }}
      />
      <CssVarsProvider theme={theme}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </Head>
        <ThemeProvider>
          <CssBaseline />
          <ErrorBoundary>
            <BuildIdProvider>
              <ClientIDProvider disableIP cookies domain={COOKIE_DOMAIN}>
                <LessonsProvider lessons={Array(Lesson).check(lessons)}>
                  <SessionProvider session={session}>
                    <InsideLogin course={course}>
                      <Warnings />
                      <Component {...pageProps} />
                    </InsideLogin>
                  </SessionProvider>
                </LessonsProvider>
              </ClientIDProvider>
            </BuildIdProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </CssVarsProvider>
    </AppCacheProvider>
  )
}
